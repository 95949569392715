import { autoinject, bindable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { connectTo } from "aurelia-store";
import { AuthenticationService } from "services/authentication-service";
import { IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";

@connectTo<State>()
@autoinject()
export class TopbarElement {
  private _authenticationService: AuthenticationService;
  userDropdownExpaned: boolean = false;
  user: IUser;
  router: Router;
  stateManager: StateManager;
  isAdmin: boolean;

  constructor(
    authenticationService: AuthenticationService,
    router: Router,
    stateManager: StateManager
  ) {
    this._authenticationService = authenticationService;
    this.router = router;
    this.stateManager = stateManager;
  }

  stateChanged(state: State) {
    this.user = state.user;
    this.isAdmin = state.isAdmin;
  }

  toggleUserDropdown() {
    this.userDropdownExpaned = !this.userDropdownExpaned;
  }

  async logout() {
    await this.stateManager.setUserNullAsync();
    await this._authenticationService.logout();
    this.router.navigateToRoute("login");
  }

  toggleSidebar() {
    document.getElementById("body").classList.toggle("toggle-sidebar");
  }

  navigateHome() {
    if (this.isAdmin) {
      this.router.navigateToRoute("today");
    } else {
      this.router.navigateToRoute("dashboard");
    }
  }
}

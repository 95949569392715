import { Router } from "aurelia-router";
import { bindable, autoinject } from "aurelia-framework";
import { State } from "services/state";
import { connectTo } from "aurelia-store";

@connectTo<State>()
@autoinject()
export class SidebarElement {
  @bindable router: Router;
  isAdmin: boolean;

  stateChanged(newState: State, oldState: State) {
    this.isAdmin = newState.isAdmin;
  }
}
